
         @import "config.scss";
      
.container {
   display: flex;
   align-items: center;
   justify-content: center;
   position: fixed;
   width: 100%;
   height: 100%;
   top: 0;
   left: 0;
   right: 0;
   margin: auto;
   z-index: 10;
   padding: 10vh 0;
   background-color: rgb(0 0 0 / 70%);

   .backdrop {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 100%;
      height: 100%;
   }

   .modal {
      display: flex;
      flex-direction: column;
      background-color: white;
      width: 100%;
      height: fit-content;
      margin: -50px 30px;
      z-index: 1;

      .btnClose {
         @extend %removeButtonStyle;
         // z-index: 100;
         cursor: pointer;

         svg {
            display: block;
         }
      }

      .content {
         padding: 0px 20px;
         padding-bottom: 20px;
         max-height: calc(100vh - 200px);
         overflow-y: auto;
      }
   }
}

.closeButton {
   display: flex;
   justify-content: flex-end;
   margin: 10px;
   z-index: 100;
   position: relative;
}
