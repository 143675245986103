
         @import "config.scss";
      
.container {
   .wrapperTitle {
      @extend %title-with-border;
      display: flex;
      justify-content: space-between;

      .sizeButton {
         @extend %button;
         color: $color-blue-200;
         cursor: pointer;
      }
   }

   .list {
      display: flex;
      flex-wrap: wrap;
   }
}
