@import './variables';
@import './include-media';

%container {
   min-height: 100vh;
   padding: 30px 16px;

   // Tablet
   @include media('>=tablet') {
      padding: 20px;
   }
   // Desktop
   @include media('>desktop') {
      padding: 30px 40px;
   }
}

%containerBreakpoint {
   max-width: $breakpoint-view;
   margin: 0 auto;
   width: 100%;
}

%removeButtonStyle {
   background-color: unset;
   border: unset;
   border-radius: unset;
   padding: 0;
   text-align: unset;
   &:active,
   &:focus {
      outline: unset;
   }
}

%modalButtonClose {
   color: $color-blue-100;
   display: flex;
   position: absolute;
   cursor: pointer;
}

%formLoginSignup {
   @extend %boxShadowForm;
   display: flex;
   align-items: center;
   border-radius: $radius-wrapper;
   background-color: $color-white;
   padding: 10px 20px;
   max-width: 500px;
   flex: 1;

   form {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
   }

   .header {
      margin: 10px;
   }

   .title {
      @extend %title;
   }

   .titleSecondary {
      @extend %headline-6;
   }

   .subtitle {
      @extend %subtitle;
   }

   .subtitleSecondary {
      @extend %subtitle-2;
      color: rgba(51, 51, 51, 0.8);
   }

   .buttons {
      display: contents;
      flex-direction: column;
      margin: 10px 5px;
      width: 100%;
      button {
         margin: 10px;
      }
      .cancel {
         display: flex;
         justify-content: center;
         width: 100%;
         margin: 10px;
      }
   }

   // Desktop
   @include media('>desktop') {
      .header {
         margin: 20px;
      }
   }
}

%backgroundTwoColors {
   background: linear-gradient(
      to bottom,
      var(--color-primary) 0%,
      var(--color-primary) 50%,
      $color-grey-100 50%,
      $color-grey-100 100%
   );
}

%img-responsive {
   width: 100%;
   height: auto;
   display: block;
}

//============================================================
// Typography
//============================================================

// TODO: No sería mejor tener esto directamente en Typography.scss ?

%title {
   @extend %headline-5;
   color: $color-title;
   font-weight: 700 !important;
   text-align: center;
   margin-bottom: 10px;
}

%subtitle {
   @extend %subtitle-1;
   color: $color-subtitle;
   font-weight: 700 !important;
   text-align: center;
   margin-bottom: 10px;
}
%brandName {
   @extend %headline-5;
   color: $color-subtitle;
   font-weight: 700 !important;
   text-align: center;
}
%product-item-name {
   @extend %subtitle-1;
   color: #000000;
   font-weight: 700 !important;
}

%product-item-price {
   @extend %subtitle-1;
   font-weight: 700 !important;
}

%product-item-original-price {
   @extend %subtitle-2;
   font-weight: normal !important;
   margin-bottom: 0;
}

%product-name {
   @extend %headline-5;
   font-weight: 700 !important;
}

%product-price {
   @extend %headline-5;
   font-weight: 700 !important;
}

%product-original-price {
   @extend %subtitle-2;
   font-weight: normal !important;
}

%product-discount {
   @extend %subtitle-2;
   font-weight: 700 !important;
   color: $color-product-discount;
}

%title-with-border {
   @extend %subtitle-2;
   color: $color-title;
   padding-bottom: 10px;
   margin: 20px 0;
   border-bottom: 1px solid $color-title-border;
}

%link-category {
   @extend %caption;
   font-weight: 700 !important;
}

%back {
   @extend %caption;
   margin: 5px 0;
   font-weight: 700 !important;
}

%badge {
   @extend %caption;
   font-weight: 700 !important;
}

%error {
   @extend %caption;
   color: $color-red;
   font-weight: 700 !important;
}

%input {
   @extend %subtitle-1;
}

%radio {
   @extend %subtitle-2;
   font-weight: normal !important;
}

%link {
   @extend %subtitle-2;
}

%installments {
   @extend %subtitle-2;
}

%free-shipping {
   @extend %subtitle-2;
}

%label {
   @extend %caption;
}

%no-image-text {
   @extend %caption;
}

%description {
   @extend %body-2;
   line-height: 1.35;
   color: $color-table;
}

%table {
   @extend %body-2;
   line-height: 1.35;
   color: $color-table;
}

%quantity-last-stock {
   @extend %body-1;
   color: $color-quantity-last-stock;
}

%link-to-shop {
   font-size: 0.9rem !important;
   padding-left: 16px;
}

%cart-item-name {
   @extend %subtitle-1;
   font-weight: 700 !important;
}

%cart-item-price {
   @extend %subtitle-1;
   font-weight: 700 !important;
}

%cart-item-original-price {
   @extend %subtitle-2;
   font-weight: normal !important;
}

%cart-item-discount {
   @extend %caption;
   padding: 8px 4px;
}
%valoracion-media {
   @extend %headline-6;
   font-weight: 600 !important;
   margin-bottom: 10px;
   font-size: 1.1rem;
}
%valoracion-title {
   @extend %headline-6;
   font-weight: 700 !important;
   margin-bottom: 10px;
}
%valoracion-name {
   @extend %subtitle-2;
   text-align: center;
   font-size: 16px !important;
   font-weight: 300 !important;
   color: #a1a1a1;
}
%valoracion-sub-title {
   @extend %headline-6;
   text-align: center;
}
%valoracion-body-text {
   @extend %body-2;
   font-size: 0.675rem;
   font-weight: 600 !important;
}
%valoracion-comment {
   @extend %body-2;
   font-size: 0.575rem;
   font-weight: 400 !important;
}
%valoracion-username {
   @extend %body-2;
   font-size: 0.575rem;
   font-weight: 100 !important;
}

// An rem font-size mixin providing fallback to px
@mixin font-size($sizeValue: 1.4) {
   $remValue: $sizeValue;
   $pxValue: ($sizeValue * 10);
   font-size: #{$pxValue}px;
   font-size: #{$remValue}rem;
}

// Requires inline-block or block for proper styling
@mixin text-truncate {
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
}

//============================================================
// Transforms
//============================================================

@mixin transform($arguments...) {
   -webkit-transform: $arguments;
   -ms-transform: $arguments; // IE9
   transform: $arguments;
}

@mixin transform-origin($arguments...) {
   -webkit-transform-origin: $arguments;
   -ms-transform-origin: $arguments; // IE9
   transform-origin: $arguments;
}

@mixin rotate($arguments...) {
   -webkit-transform: rotate($arguments);
   -ms-transform: rotate($arguments); // IE9
   transform: rotate($arguments);
}

@mixin backface-visibility($arguments) {
   -webkit-backface-visibility: $arguments;
   -moz-backface-visibility: $arguments;
   -ms-backface-visibility: $arguments;
   -o-backface-visibility: $arguments;
   backface-visibility: $arguments;
}

//============================================================
// Animations
//============================================================

@mixin transition($arguments...) {
   -webkit-transition: $arguments;
   transition: $arguments;
}

@mixin animation($arguments) {
   -webkit-animation: $arguments;
   animation: $arguments;
}

//============================================================
// Gradients
//============================================================

@mixin linear-gradient($from, $to) {
   background-color: $from;
   background-image: -webkit-linear-gradient(
      top,
      $from,
      $to
   ); /* Chrome 10-25, iOS 5+, Safari 5.1+ */
   background-image: linear-gradient(
      to bottom,
      $from,
      $to
   ); /* Chrome 26, Firefox 16+, IE 10+, Opera 12.10+ */
}

//============================================================
// Structure
//============================================================

@mixin box-sizing($arguments) {
   -webkit-box-sizing: $arguments;
   -moz-box-sizing: $arguments;
   -o-box-sizing: $arguments;
   box-sizing: $arguments;
}

//============================================================
//
// Easing
//
// Thanks to Robert Penner for his sterling work on easing,
// and to Matthew Lein for converting these functions into
// approximated cubic-bezier functions. Respect.
//
// @see http://robertpenner.com/
// @see http://matthewlein.com/ceaser/
//
//============================================================

// Cubic
$easeInCubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$easeOutCubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$easeInOutCubic: cubic-bezier(0.645, 0.045, 0.355, 1);

// Circ
$easeInCirc: cubic-bezier(0.6, 0.04, 0.98, 0.335);
$easeOutCirc: cubic-bezier(0.075, 0.82, 0.165, 1);
$easeInOutCirc: cubic-bezier(0.785, 0.135, 0.15, 0.86);

// Expo
$easeInExpo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
$easeOutExpo: cubic-bezier(0.19, 1, 0.22, 1);
$easeInOutExpo: cubic-bezier(1, 0, 0, 1);

// Quad
$easeInQuad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
$easeOutQuad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$easeInOutQuad: cubic-bezier(0.455, 0.03, 0.515, 0.955);

// Quart
$easeInQuart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
$easeOutQuart: cubic-bezier(0.165, 0.84, 0.44, 1);
$easeInOutQuart: cubic-bezier(0.77, 0, 0.175, 1);

// Quint
$easeInQuint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$easeOutQuint: cubic-bezier(0.23, 1, 0.32, 1);
$easeInOutQuint: cubic-bezier(0.86, 0, 0.07, 1);

// Sine
$easeInSine: cubic-bezier(0.47, 0, 0.745, 0.715);
$easeOutSine: cubic-bezier(0.39, 0.575, 0.565, 1);
$easeInOutSine: cubic-bezier(0.445, 0.05, 0.55, 0.95);

// Back
$easeInBack: cubic-bezier(0.6, -0.28, 0.735, 0.045);
$easeOutBack: cubic-bezier(0.175, 0.885, 0.32, 1.275);
$easeInOutBack: cubic-bezier(0.68, -0.55, 0.265, 1.55);
