%flexCenter {
   display: flex;
   justify-content: center;
   align-items: center;
}

@mixin borderRadius($value) {
   -webkit-border-radius: $value;
   -moz-border-radius: $value;
   border-radius: $value;
}

%boxShadowProductItem {
   box-shadow: $color-black-100 0 2px 4px 0;
   -webkit-box-shadow: $color-black-100 0 2px 4px 0;
   -moz-box-shadow: $color-black-100 0 2px 4px 0;
}
%boxShadowDataUser {
   box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.12);
}
%boxShadowOnProductItem {
   box-shadow: 0 7px 16px 0 rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.1);
   -webkit-box-shadow: 0 7px 16px 0 rgba(0, 0, 0, 0.2),
      0 1px 3px 0 rgba(0, 0, 0, 0.1);
   -moz-box-shadow: 0 7px 16px 0 rgba(0, 0, 0, 0.2),
      0 1px 3px 0 rgba(0, 0, 0, 0.1);
}

%boxShadowForm {
   box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
      0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
   -webkit-box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
      0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
   -moz-box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
      0px 3px 4px 0px rgba(2, 1, 1, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

%boxShadowMenu {
   box-shadow: 0 3px 8px $color-black-300;
   -webkit-box-shadow: 0 3px 8px $color-black-300;
   -moz-box-shadow: 0 3px 8px $color-black-300;
}

@function setButtonTextColor($color) {
   @if (lightness($color) > 40) {
      @return black;
   } @else {
      @return white;
   }
}

@mixin transition($value) {
   -webkit-transition: $value;
   -moz-transition: $value;
   -o-transition: $value;
   transition: $value;
}

@mixin transform($value) {
   -webkit-transform: $value;
   -moz-transform: $value;
   -ms-transform: $value;
   -o-transform: $value;
   transform: $value;
}

@mixin opacity($value) {
   opacity: $value;
   -moz-opacity: $value;
   -khtml-opacity: $value;
   -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=($value * 100));
   filter: alpha(Opacity=($value * 100));
}

%sticky {
   position: -webkit-sticky;
   position: sticky;
}
