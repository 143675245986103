/* First tier */

/* Colors */

$color-black-50: rgba(0, 0, 0, 0.08);
$color-black-100: rgba(0, 0, 0, 0.1);
$color-black-150: rgba(0, 0, 0, 0.2);
$color-black-200: rgba(0, 0, 0, 0.4);
$color-black-300: rgba(51, 51, 51, 0.8);
$color-black-400: #333;
$color-black-500: #000;
$color-grey-100: #eaeaea;
$color-grey-150: #eee;
$color-grey-200: #d1d3d4;
$color-grey-250: #ddd;
$color-white: #fff;
$color-white-50: #f0f0f0;
$color-yellow: #ffd100;
$color-green: #336535;
$color-blue-100: #428bca;
$color-blue-200: #2a6496;
$color-blue-300: #007bff;
$color-blue-500: #428bca;
$color-blue-400: #0000ff;
$color-blue-600: #1a6394;

$color-red: #d9585e;

/* Second tier */

$color-container: var(--color-primary);

$color-on-content: $color-grey-200;
$color-on-select: $color-grey-100;

$color-product-item-bg: $color-white;
$color-product-item-border: $color-grey-150;
$color-product-item: $color-black-500;
$color-product-item-discount-bg: var(--color-primary);

$color-installments: $color-green;

$color-free-shipping: $color-green;

$color-btn-primary: var(--color-primary);
$color-btn-primary-bg: var(--color-primary);
$color-btn-primary-bg__active: var(--color-primary-light);
$color-btn-primary-border: var(--color-primary);
$color-on-btn-primary-outlined-bg: var(--color-primary);
$color-btn-secondary: var(--color-secondary);
$color-btn-secondary-bg: var(--color-secondary);
$color-btn-secondary-bg__active: var(--color-secondary-light);
$color-btn-secondary-border: var(--color-secondary);
$color-on-btn-secondary-outlined-bg: var(--color-secondary);
$color-btn-outlined: $color-white;
$color-btn-outlined-bg: transparent;
$color-on-btn-outlined: $color-black-400;
$color-btn-cancel: $color-grey-150;
$color-btn-disabled-bg: $color-black-300;
$color-on-btn-disabled-bg: $color-black-300;
$color-btn-disabled: $color-white;

$color-title: $color-black-400;
$color-title-border: $color-grey-150;

$color-subtitle: $color-black-400;

$color-navbar-link: $color-black-500;

$color-dropdown-menu: $color-white;

$color-image-bg: $color-grey-100;
$color-image-link: var(--color-primary);
$color-on-image-link: var(--color-secondary);

$color-label: $color-black-500;

$color-input-border: $color-black-150;
$color-input-on-border: $color-blue-100;

$color-checkbox-border: var(--color-primary);
$color-checkbox-bg: var(--color-primary);
$color-radio-border: $color-black-50;
$color-radio: $color-white-50;
$color-radio-dot: var(--color-primary);
$color-on-radio-dot: $color-black-150;

$color-link: $color-blue-100;
$color-on-link: $color-blue-200;

$color-pagination-border: $color-grey-250;
$color-pagination-item: var(--color-secondary-pag);
$color-on-pagination-item-bg: $color-grey-100;
$color-pagination-item-bg--active: var(--color-secondary-pag);
$color-pagination-item--active: var(--color-primary-pag);
$color-pagination-item-border--active: var(--color-secondary-pag);
$color-on-pagination-item--active: var(--color-secondary-pag);
$color-pagination-item--disabled: $color-black-100;

$color-size-item: $color-black-500;
$color-size-item-bg: $color-grey-200;
$color-active-size-item-bg: $color-black-400;
$color-active-size-item: $color-white;
$color-disabled-size-item-bg: $color-black-500;
$color-disabled-size-item: $color-white;

$color-action: $color-blue-100;
$color-on-action: $color-blue-200;

$color-description: $color-black-400;

$color-table: $color-black-400;
$color-table-border: $color-grey-100;
$color-table-cell-1-bg: $color-grey-200;
$color-table-cell-2-bg: $color-grey-150;
$color-table-cell-3-bg: $color-white;

$color-on-image-item-border: $color-grey-200;

$color-product-discount: $color-red;

$color-quantity-last-stock: $color-red;
$color-quantity-count: $color-black-300;
$color-quantity-item-bg: $color-white;
$color-on-quantity-item-bg: $color-grey-100;
$color-quantity-form-label: $color-black-300;
$color-quantity-form-input-border: $color-grey-100;

$color-cart-button-icon-badge-bg: $color-red;
$color-cart-button-icon-badge: $color-white;

$color-checkout-button: $color-grey-100;

$color-modal-bg: $color-white;
$color-modal-background: $color-black-200;

$color-loading-bg: $color-black-200;
$color-loading: $color-white;

/* Sizes */

$height-topbar: 50px;
$icon-size: 18px;

/* Radius */

$radius-input: 4px;
$radius-button: 4px;
$radius-product-item: 4px;
$radius-pagination: 4px;
$radius-badge: 4px;
$radius-dropdown: 4px;
$radius-checkbox-dot: 4px;
$radius-checkbox: 6px;
$radius-table: 6px;
$radius-checkout-button: 6px;
$radius-wrapper: 6px;
$radius-modal: 6px;
$radius-imagen: 8px;
$radius-icon-badge: 50%;
$radius-input-newsletter: 40px;

/* Border */

$border-product-item: 1px;
$border-checkbox: 1px;
$border-pagination: 1px;
$border-radio: 2px;
$border-input: 1px;
$border-table: 1px;
$border-checkout-button: 1px;
$border-button: 2px;
$border-select: 4px;
$border-item: 4px;

$breakpoint-view: 1440px;
