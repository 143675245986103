
         @import "config.scss";
      
.wrapper {
   position: relative;
   overflow: hidden;
   display: flex;
   flex: 1;
   flex-direction: column;

   .title {
      @extend %headline-5;
      margin-bottom: 20px;
      text-align: center;
   }

   .item {
      display: block !important;
      margin: auto;
      list-style: none;
      padding: 0 5px;
   }
   .carousel {
      width: 100%;
      margin: auto;
      padding: 0px;
   }
}
@include media('>=tablet') {
   .wrapper {
      .item {
         padding: 10px;
      }
      .carousel {
         padding: 0 50px;
      }
   }
}
