
         @import "config.scss";
      
.arrow {
   @extend %removeButtonStyle;
   position: absolute;
   top: 50%;
   -webkit-transform: translate(0, -50%);
   -ms-transform: translate(0, -50%);
   transform: translate(0, -50%);
   z-index: 1;
   cursor: pointer;

   svg {
      width: 52px;
      height: 52px;
   }
   &--left {
      left: 0;
   }
   &--right {
      right: 0;
   }
}

.arrowCity {
   color: var(--color-primary);
}
