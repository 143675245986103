
         @import "config.scss";
      
.container {
   overflow-x: scroll;
   scroll-snap-type: x mandatory;
   height: auto;
   // margin: 0 40px;

   .content {
      display: block;

      .item {
         display: block;
         float: left;
         scroll-snap-align: start;
      }
   }
}

.container::-webkit-scrollbar {
   display: none;
}
