
         @import "config.scss";
      
.wrapper {
   max-height: 100px;

   .title {
      @extend %subtitle;
   }

   .item {
      display: block !important;
      margin: auto;
      list-style: none;
      width: 155px !important;
   }
}

// Desktop
@include media('>desktop') {
   .wrapper {
      .item {
         width: 140px !important;
      }
   }
}
