
         @import "config.scss";
      
.wrapper {
   position: relative;
   cursor: pointer;
   width: 50%;
   height: 50%;

   .image {
      display: flex;
      border: 1px solid transparent;
      border-radius: 8px;

      &:hover,
      &:focus,
      &:active {
         border: 1px solid $color-grey-100;
      }
   }

   .placeholder {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1;
   }
}
