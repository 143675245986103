
         @import "config.scss";
      
.container {
   @extend %containerBreakpoint;
   display: grid;
   grid-gap: 20px;

   .benefitsMobile {
      overflow: hidden;
   }

   .carousel {
      max-height: 600px;
      position: relative;
      overflow: hidden;
      margin-bottom: 30px;
   }
}

.bannersHome {
   display: grid;
   grid-gap: 10px;
   min-height: 500px;

   .carouselBrands {
      margin-bottom: 0;
   }

   @include media('>=tablet') {
      grid-template-areas:
         'banner-large banner-large'
         'carousel carousel'
         'banner-small-1 banner-small-2';

      .bannerLarge {
         grid-area: banner-large;
         max-height: 440px;
         overflow: hidden;
      }
      .carouselBrands {
         grid-area: carousel;
      }
      .bannerSmall1 {
         grid-area: banner-small-1;
      }
      .bannerSmall2 {
         grid-area: banner-small-2;
      }
   }
}

.bannersGender,
.bannersSport {
   a {
      display: block;
      margin: 10px 0;
   }
}

@include media('<tablet') {
   .benefitsDesktop {
      display: none;
   }
}
@include media('>=tablet') {
   .benefitsMobile {
      display: none;
   }

   .bannersGender,
   .bannersSport {
      display: flex;
      flex-direction: row;
      a {
         overflow: hidden;
         margin: 10px;
         width: 100%;
         border-radius: 12px;
      }
   }

   .bannersGender {
      img {
         filter: grayscale(100%);
         transition: all 0.25s;

         &:hover {
            filter: grayscale(0%);
         }
      }
   }
}

// Desktop
@include media('>desktop') {
   .container {
      grid-template-columns: 100%;

      .carousel {
         align-self: center;
         justify-self: center;
         width: 80%;
      }
   }
}
