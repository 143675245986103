
         @import "config.scss";
      
.modalContainer {
   display: flex;
   width: 100%;
   height: 100%;

   .wrapperTop {
      height: fit-content;
      .productBrandContainer {
         display: flex;
         align-items: center;
         justify-content: space-between;
      }

      .sku {
         @extend %subtitle-1;
         margin-top: 10px;
      }

      .buttonContainer {
         display: flex;
         flex-direction: column;
      }
   }
}
.spinner {
   width: 100%;
   height: 70vh;
   display: flex;
   justify-content: center;
   align-items: center;
}

.modalStyle {
   max-width: 100%;
}

@include media('<tablet') {
   .modalContainer {
      display: flex;
      flex-wrap: wrap;
   }
}

.detailButton {
   background-color: #d1d3d4;
   margin-top: 5px;
}
