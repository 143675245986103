
         @import "config.scss";
      
.item {
   @extend %flexCenter;
   @extend %label;
   @include opacity(0.7);
   @include borderRadius($border-item);
   position: relative;
   color: $color-size-item;
   background-color: $color-size-item-bg;
   width: 50px;
   height: 50px;
   margin: 0 15px 10px 0;

   &:hover,
   &:active,
   &:focus {
      @include opacity(1);
      @include transition(all 0.25s ease);
      cursor: pointer;
   }

   &--active {
      color: var(--color-primary-contrast);
      background-color: var(--color-primary);
      @include opacity(1);
   }

   &--disabled {
      @include opacity(0.7);
      background-color: $color-disabled-size-item-bg;
      color: $color-disabled-size-item;

      &:hover,
      &:active,
      &:focus {
         @include opacity(0.7);
         cursor: not-allowed;
      }
   }

   .icon {
      fill: $color-red;
      position: absolute;
      width: 50px;
      height: 50px;
   }
}
