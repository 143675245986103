
         @import "config.scss";
      
.wrapper {
   display: flex;
   align-items: center;
   // height: 65px;
   width: 100%;
   margin-block: 0.5rem;

   .currentPrice {
      @extend %product-price;
   }
   .originalPrice {
      @extend %product-original-price;
      margin-left: 10px;
   }
   .priceInstallments {
      display: flex;
      flex-direction: column;
      width: 100%;
   }
   .discount {
      @extend %product-discount;
      margin-left: 10px;
   }
   .installments {
      margin-top: 1rem;
      gap: 0.5rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
   }
   .promotions {
      @extend %button;
      border: none;
      background-color: transparent;
      cursor: pointer;
      color: #2a6496;
      padding: 0;
   }
}

.modalPrice {
   width: fit-content;
   @extend %subtitle;
   padding: 1rem;

   & > div {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      gap: 3rem;
      margin-bottom: 1rem;

      & > div {
         text-align: start;
      }
   }
}

.modalCardContainer {
   display: flex;
   flex-direction: column;
   gap: 2rem;
   margin-inline: auto;
   margin-block: 1rem;
}

.card {
   box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14),
      0px 1px 8px 0px rgba(0, 0, 0, 0.12);
   border-radius: 8px;
}

.cardTitle {
   @extend %subtitle-2;
   background-color: rgb(255, 209, 0);
   border-radius: 8px 8px 0 0;
   padding: 0.5rem 1rem;
   text-align: center;
}

.cardInstallments {
   @extend %body-2;
   padding: 0.5rem 1rem;
   text-align: center;
}

.creditCards {
   display: flex;
   justify-content: space-evenly;
}

.creditCard {
   display: flex;
   margin: 5px;
}

.iconed {
   display: flex;
   align-content: center;
   gap: 0.5rem;
}
