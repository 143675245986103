
         @import "config.scss";
      
.wrapper {
   display: grid;
   grid-template-columns: 1fr;
   grid-template-rows: 1fr 80px;
   grid-gap: 10px;

   .thumbnailList {
      display: grid;
      grid-auto-flow: column;
      align-content: center;
      align-items: center;
      justify-content: center;
      grid-gap: 10px;
      grid-row: 2;
      overflow-x: auto;
   }
}
.youtubeThumbnails {
   background-color: transparent;
   border: none;
   display: flex;
   padding: 0px;
}

// Desktop
@include media('>desktop') {
   .wrapper {
      grid-template-columns: 80px 1fr;
      grid-template-rows: 1fr;

      .thumbnailList {
         grid-auto-flow: row;
         grid-row: 1;
         align-content: start;
      }
   }
}

.modalStyles {
   background-color: 'transparent';
   justify-content: 'center';
   margin: 0;
   padding: 0;

   // :last-child {
   //    display: flex;
   // }
}
