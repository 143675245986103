
         @import "config.scss";
      
.wrapper {
   @include borderRadius($radius-imagen);
   display: flex;
   position: relative;
   height: 100%;
   width: 100%;

   .zoom {
      height: 100%;
   }
   .placeholder {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 2;
   }
}
.onModal {
   @include borderRadius($radius-imagen);
   display: flex;
   position: relative;
   height: 100%;
   width: 75%;

   .zoom {
      height: 100%;
   }
   .placeholder {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 2;
   }
}

@include media('<tablet') {
   .onModal {
      height: 100%;
      width: 100%;
   }
   
}

