
         @import "config.scss";
      
.container {
   display: flex;
   justify-content: center;
   position: fixed;
   width: 100%;
   height: 100%;
   top: 0;
   left: 0;
   right: 0;
   margin: auto;
   z-index: 10;
   padding: 10vh 0;
   background-color: rgb(0 0 0 / 70%);

   .header {
      background-color: var(--color-primary);
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px;
      margin-bottom: 10px;
      @extend %subtitle-2;
   }

   .subTitle {
      @extend %subtitle-2;
      margin-bottom: 10px;
   }

   .backdrop {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 100%;
      height: auto;
   }

   .modal {
      display: flex;
      flex-direction: column;
      background-color: white;
      width: 50%;
      height: 80%;
      z-index: 1;

      .btnClose {
         @extend %removeButtonStyle;
         cursor: pointer;

         svg {
            display: block;
         }
      }

      .content {
         @extend %body-1;
         padding: 0px 20px;
         padding-bottom: 20px;
         max-height: calc(130vh - 200px);
         overflow-y: auto;
         line-height: 25px;
      }
   }
}
.closeButton {
   display: flex;
   justify-content: flex-end;
   margin: 10px;
}

@include media('<tablet') {
   .container .modal {
      width: 90%;
      height: 90%;
   }
}
