
         @import "config.scss";
      
.container {
   display: flex;
   flex-direction: column;
   align-items: flex-end;
   position: absolute;
   background-color: #1d1d1d;
   pointer-events: none;
   border-radius: 5px;
   color: white;
   padding: 5px 8px;
   bottom: 60px;
   transition: all 250ms ease;

   .big {
      @extend %headline-6;
      margin-bottom: 5px;
   }

   .small {
      @extend %body-2;
      margin-top: 2px;
      white-space: nowrap;
   }
}
