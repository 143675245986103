%font {
   font-family: 'Montserrat', 'Segoe UI', sans-serif;
}

%headline-1 {
   @extend %font;
   font: {
      size: 6.0625rem;
      weight: 300;
   }
   letter-spacing: -0.09375rem;
}

%headline-2 {
   @extend %font;
   font: {
      size: 3.8125rem;
      weight: 300;
   }
   letter-spacing: -0.03125rem;
}

%headline-3 {
   @extend %font;
   font: {
      size: 3rem;
      weight: 400;
   }
   letter-spacing: 0rem;
}

%headline-4 {
   @extend %font;
   font: {
      size: 2.125rem;
      weight: 400;
   }
   letter-spacing: 0.015625rem;
}

%headline-5 {
   @extend %font;
   font: {
      size: 1.5rem;
      weight: 400;
   }
   letter-spacing: 0rem;
}

%headline-6 {
   @extend %font;
   font: {
      size: 1.25rem;
      weight: 500;
   }
   letter-spacing: 0.009375rem;
}

%subtitle-1 {
   @extend %font;
   font: {
      size: 1rem;
      weight: 400;
   }
   letter-spacing: 0.009375rem;
}

%subtitle-2 {
   @extend %font;
   font: {
      size: 0.875rem;
      weight: 500;
   }
   letter-spacing: 0.00625rem;
}

%body-1 {
   @extend %font;
   font: {
      size: 1rem;
      weight: 400;
   }
   letter-spacing: 0.03125rem;
}

%body-2 {
   @extend %font;
   font: {
      size: 0.875rem;
      weight: 400;
   }
   letter-spacing: 0.015625rem;
}

%button {
   @extend %font;
   font: {
      size: 0.875rem;
      weight: 500;
   }
   letter-spacing: 0.078125rem;
}

%caption {
   @extend %font;
   font: {
      size: 0.75rem;
      weight: 400;
   }
   letter-spacing: 0.025rem;
}

%overline {
   @extend %font;
   font: {
      size: 0.625rem;
      weight: 400;
   }
   letter-spacing: 0.09375rem;
}
