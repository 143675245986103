
         @import "config.scss";
      
.wrapper {
   display: flex;
   justify-content: center;
   align-items: center;
   min-height: 100px;

   .image {
      transition: all 250ms ease-out 0s;
      position: absolute;
      width: 100%;

      &:hover {
         opacity: 1;
      }
   }

   .brandName {
      display: flex;
      justify-content: center;
      align-items: center;
      @extend %brandName;
   }
   .white {
      color: white;
   }
   .black {
      color: black;
   }
}
