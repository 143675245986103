
         @import "config.scss";
      
.wrapper {
   margin-right: 10px;

   .wrapperTop {
      display: flex;
      align-items: center;

      .genderSport {
         @extend %label;
         margin-right: 10px;
      }
   }

   .name {
      @extend %product-name;
      margin-top: 5px;
   }
}
