
         @import "config.scss";
      
.image {
   width: 80px;
   height: 40px;
   padding: 0 4px;
   cursor: pointer;

   &:hover,
   &:active,
   &:focus {
      @include opacity(0.9);
   }
}
