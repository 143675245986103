
         @import "config.scss";
      
.container {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   cursor: pointer;

   .text {
      @extend %body-2;
      margin-top: 6px;
   }
}
